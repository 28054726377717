import axios from 'axios';

// Criando instância do axios com configurações base
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL ,
  timeout: 30000, // Aumentado para 30s para arquivos grandes
});

// Interceptor para tratamento de erros com mensagens amigáveis
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 413) {
      throw new Error('Arquivo muito grande. Por favor, tente um arquivo menor.');
    }
    if (error.response?.status === 415) {
      throw new Error('Tipo de arquivo não suportado.');
    }
    if (error.code === 'ECONNABORTED') {
      throw new Error('A conexão demorou muito. Por favor, tente novamente.');
    }
    console.error('Erro na requisição:', error);
    throw error;
  }
);

// Adicionar interceptor para incluir token em todas as requisições
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const documentService = {
  upload: async (files) => {
    const formData = new FormData();
    
    try {
      // Converter para array se não for
      const filesArray = Array.isArray(files) ? files : Array.from(files);
      
      // Validar tipos de arquivo permitidos
      const allowedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain'
      ];

      // Validar cada arquivo
      filesArray.forEach(file => {
        if (!allowedTypes.includes(file.type)) {
          throw new Error(`Tipo de arquivo não suportado: ${file.name}`);
        }
        formData.append('files', file);
      });

      const response = await api.post('/documents/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log('Progresso do upload:', percentCompleted + '%');
        },
      });

      return response.data;
    } catch (error) {
      console.error('Erro no upload:', error);
      throw error;
    }
  },

  getStatus: async () => {
    try {
      const response = await api.get('/documents/status');
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar status:', error);
      throw error;
    }
  },

  deleteCollection: async () => {
    try {
      const response = await api.delete('/documents');
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar coleção:', error);
      throw error;
    }
  }
};

export const customerService = {
  uploadCSV: async (file) => {
    const formData = new FormData();

    try {
      // Validar tipo do arquivo
      if (!file.name.endsWith('.csv')) {
        throw new Error('Por favor, envie apenas arquivos CSV');
      }

      formData.append('file', file);

      const response = await api.post('/customers/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log('Progresso do upload CSV:', percentCompleted + '%');
        },
      });

      return response.data;
    } catch (error) {
      console.error('Erro no upload CSV:', error);
      throw error;
    }
  }
};

export const chatService = {
  getStats() {
    return api.get('/chats/stats')
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },

  getMessageMetrics() {
    return api.get('/chats/metrics/messages')
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },

  searchChats(searchTerm, page = 1, perPage = 20) {
    return api.get('/chats', {
      params: {
        search: searchTerm,
        page,
        per_page: perPage
      }
    })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
  },

  getChatConversation(sessionId) {
    return api.get(`/chats/${sessionId}/conversation`)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  },

  getPipelineData: async (skip = 0, cpf = '') => {
    try {
      const params = new URLSearchParams({ skip, limit: 20 });
      if (cpf) params.append('cpf', cpf);
      
      const response = await api.get(`/chats/pipeline?${params}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar dados da esteira:', error);
      throw error;
    }
  },
};

export const authService = {
  login: async (email, password) => {
    const formData = new FormData();
    formData.append('username', email);
    formData.append('password', password);

    const response = await api.post('/auth/login', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },

  register: async (userData) => {
    const response = await api.post('/auth/register', userData);
    return response.data;
  },

  getCurrentUser: async () => {
    const response = await api.get('/auth/me');
    return response.data;
  },
};

export const vctexService = {
  getFormalizationLink: async (contractNumber) => {
    try {
      const response = await api.post('/vctex/proposal/link', {
        contract_number: contractNumber
      });
      
      if (response.data.status) {
        return response.data.status;
      }
      
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      
      throw new Error('Link de formalização não encontrado');
    } catch (error) {
      console.error('Erro ao obter link de formalização:', error);
      throw error;
    }
  }
};