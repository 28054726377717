import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { simulateCredit, createProposal, getProposalLink } from '../../services/vctexService';
import { useProposal } from '../../context/ProposalContext';
import { toast } from 'react-toastify';
import { fetchAddressByCep } from '../../services/cepService';

// Regex patterns for validations
const PATTERNS = {
  cpf: /^\d{11}$/,
  phone: /^\d{10,11}$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  zipCode: /^\d{8}$/,
  onlyNumbers: /^\d+$/,
  date: /^\d{4}-\d{2}-\d{2}$/
};

const GENDER_OPTIONS = [
  { value: 'M', label: 'Masculino' },
  { value: 'F', label: 'Feminino' }
];

const MARITAL_STATUS_OPTIONS = [
  { value: 'solteiro', label: 'Solteiro(a)' },
  { value: 'casado', label: 'Casado(a)' },
  { value: 'divorciado', label: 'Divorciado(a)' },
  { value: 'viuvo', label: 'Viúvo(a)' }
];

const DOCUMENT_TYPES = [
  { value: 'rg', label: 'RG' },
  { value: 'cnh', label: 'CNH' }
];

const ACCOUNT_TYPES = [
  { value: 'corrente', label: 'Conta Corrente' },
  { value: 'poupanca', label: 'Conta Poupança' }
];

export const ProposalForm = ({
  loading,
  banks = [],
  selectedBank,
  onBankChange,
  loadingBanks
}) => {
  const [cpf, setCpf] = useState('');
  const [stage, setStage] = useState(0);
  const [maxStage, setMaxStage] = useState(0);
  const [sendingProposal, setSendingProposal] = useState(false);
  const [formalizationLink, setFormalizationLink] = useState('');
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [simulationError, setSimulationError] = useState(null);

  // Função para resetar todos os estados
  const resetAllStates = () => {
    setCpf('');
    setStage(0);
    setMaxStage(0);
    setSendingProposal(false);
    setFormalizationLink('');
    setLoadingAddress(false);
    setSimulationData(null);
    setFinancialId(null);
    setSimulationError(null);
    setValue('cpf', '');
    onBankChange(null);
  };

  // Efeito de limpeza quando o componente for desmontado
  useEffect(() => {
    // Não precisamos fazer nada na montagem
    return resetAllStates;
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps
  // Desabilitamos a regra do exhaustive-deps porque queremos que a função de cleanup
  // use sempre a última versão das funções, mas não queremos que o efeito seja recriado

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues: {
      name: '',
      cpf: '',
      birthdate: '',
      gender: '',
      phoneNumber: '',
      email: '',
      maritalStatus: '',
      motherName: '',
      type: '',
      numberDocument: '',
      issuingState: '',
      issuingAuthority: '',
      issueDate: '',
      zipCode: '',
      street: '',
      number: '',
      neighborhood: '',
      city: '',
      state: '',
      bankCode: '',
      accountType: '',
      accountNumber: '',
      accountDigit: '',
      branchNumber: '',
      complement: ''
    },
    mode: 'onChange'
  });

  const { financialId, setFinancialId, simulationData, setSimulationData } = useProposal();

  const renderField = ({ name, label, type = 'text', placeholder, options, disabled = false, mask, pattern, required = true, maxLength, loading }) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? `${label} é obrigatório` : false,
          pattern: pattern ? {
            value: PATTERNS[pattern],
            message: `${label} inválido`
          } : undefined
        }}
        render={({ field }) => (
          <div>
            <label htmlFor={name} className="block text-sm font-medium text-gray-700">
              {label} {required && <span className="text-red-500">*</span>}
            </label>
            <div className="mt-1">
              {options ? (
                <select
                  {...field}
                  id={name}
                  disabled={disabled}
                  className={`block w-full rounded-md shadow-sm sm:text-sm ${
                    errors[name] ? 'border-red-300 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-[#19bc5a] focus:border-[#19bc5a]'
                  }`}
                >
                  <option value="">Selecione</option>
                  {options.map(opt => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  {...field}
                  type={type}
                  id={name}
                  placeholder={placeholder}
                  disabled={disabled}
                  maxLength={maxLength}
                  className={`block w-full rounded-md shadow-sm sm:text-sm ${
                    errors[name] ? 'border-red-300 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-[#19bc5a] focus:border-[#19bc5a]'
                  }`}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (mask === 'cpf') {
                      value = value.replace(/\D/g, '').slice(0, 11);
                    } else if (mask === 'phone') {
                      value = value.replace(/\D/g, '').slice(0, 11);
                    } else if (mask === 'zipCode') {
                      value = value.replace(/\D/g, '').slice(0, 8);
                    }
                    field.onChange(value);
                  }}
                  onBlur={(e) => {
                    if (name === 'zipCode') {
                      handleCepBlur(e.target.value);
                    }
                  }}
                />
              )}
            </div>
            {errors[name] && (
              <p className="mt-1 text-sm text-red-600">
                {errors[name].message}
              </p>
            )}
          </div>
        )}
      />
    );
  };

  const handleCepBlur = async (cep) => {
    if (cep && cep.length === 8) {
      setLoadingAddress(true);
      toast.info('Buscando endereço...', { autoClose: 2000 });
      try {
        const address = await fetchAddressByCep(cep);
        if (address) {
          setValue('street', address.street);
          setValue('neighborhood', address.neighborhood);
          setValue('city', address.city);
          setValue('state', address.state);
          toast.success('Endereço encontrado!', { autoClose: 2000 });
        }
      } catch (error) {
        toast.error('Erro ao buscar endereço. Por favor, preencha manualmente.');
      } finally {
        setLoadingAddress(false);
      }
    }
  };

  const renderPersonalInfoFields = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {renderField({ name: 'name', label: 'Nome Completo', placeholder: 'Digite o nome completo' })}
      {renderField({ name: 'cpf', label: 'CPF', mask: 'cpf', pattern: 'cpf', placeholder: 'Digite apenas números' })}
      {renderField({ name: 'birthdate', label: 'Data de Nascimento', type: 'date' })}
      {renderField({ name: 'gender', label: 'Gênero', options: GENDER_OPTIONS })}
      {renderField({ name: 'phoneNumber', label: 'Telefone', mask: 'phone', pattern: 'phone', placeholder: 'Digite apenas números' })}
      {renderField({ name: 'email', label: 'E-mail', type: 'email', pattern: 'email' })}
      {renderField({ name: 'maritalStatus', label: 'Estado Civil', options: MARITAL_STATUS_OPTIONS })}
      {renderField({ name: 'motherName', label: 'Nome da Mãe', placeholder: 'Digite o nome completo da mãe' })}
    </div>
  );

  const renderDocumentFields = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {renderField({ name: 'type', label: 'Tipo de Documento', options: DOCUMENT_TYPES })}
      {renderField({ name: 'numberDocument', label: 'Número do Documento', placeholder: 'Digite o número do documento' })}
      {renderField({ name: 'issuingState', label: 'Estado Emissor', placeholder: 'UF ex: RJ, SP' })}
      {renderField({ name: 'issuingAuthority', label: 'Órgão Emissor', placeholder: 'Ex: DETRAN' })}
      {renderField({ name: 'issueDate', label: 'Data de Emissão', type: 'date' })}
    </div>
  );

  const renderAddressFields = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {renderField({
        name: 'zipCode',
        label: 'CEP',
        placeholder: 'Digite o CEP',
        pattern: 'zipCode',
        maxLength: 8,
        loading: loadingAddress
      })}
      {renderField({
        name: 'street',
        label: 'Rua',
        placeholder: 'Digite o nome da rua',
        disabled: loadingAddress
      })}
      {renderField({
        name: 'number',
        label: 'Número',
        placeholder: 'Digite o número'
      })}
      {renderField({
        name: 'complement',
        label: 'Complemento',
        placeholder: 'Digite o complemento',
        required: false
      })}
      {renderField({
        name: 'neighborhood',
        label: 'Bairro',
        placeholder: 'Digite o bairro',
        disabled: loadingAddress
      })}
      {renderField({
        name: 'city',
        label: 'Cidade',
        placeholder: 'Digite a cidade',
        disabled: loadingAddress
      })}
      {renderField({
        name: 'state',
        label: 'Estado',
        placeholder: 'Digite o estado',
        disabled: loadingAddress
      })}
    </div>
  );

  const renderBankFields = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {renderField({ 
        name: 'bankCode', 
        label: 'Código do Banco',
        placeholder: 'Digite o código do banco',
        mask: 'onlyNumbers',
        pattern: 'onlyNumbers'
      })}
      {renderField({ name: 'accountType', label: 'Tipo de Conta', options: ACCOUNT_TYPES })}
      {renderField({ name: 'accountNumber', label: 'Número da Conta', placeholder: 'Digite o número da conta' })}
      {renderField({ name: 'accountDigit', label: 'Dígito da Conta', placeholder: 'Digite o dígito' })}
      {renderField({ name: 'branchNumber', label: 'Agência', placeholder: 'Digite o número da agência' })}
    </div>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedBank) {
      setSimulationError('Por favor, selecione um banco');
      toast.error('Por favor, selecione um banco');
      return;
    }
    if (cpf.length === 11) {
      try {
        setSimulationError(null);
        // Limpa estados anteriores antes de nova simulação
        setSimulationData(null);
        setFinancialId(null);
        setStage(0);
        setMaxStage(0);
        setValue('cpf', '');
        
        const result = await simulateCredit(cpf);
        setSimulationData(result);
        setFinancialId(result.financialId);
        setValue('cpf', cpf);
        // Mantém no estágio 0 para mostrar os resultados
        toast.success('Simulação realizada com sucesso!');
      } catch (error) {
        // Em caso de erro, também limpa os estados
        setSimulationData(null);
        setFinancialId(null);
        setStage(0);
        setMaxStage(0);
        setValue('cpf', '');
        
        setSimulationError(error.message);
        toast.error(error.message, {
          autoClose: 8000
        });
      }
    }
  };

  const handleContinueProposal = () => {
    if (simulationData && financialId) {
      setStage(1);
      setMaxStage(1);
    } else {
      toast.error('É necessário realizar uma simulação primeiro');
    }
  };

  const handleNewSimulation = () => {
    setCpf('');
    setSimulationData(null);
    setFinancialId(null);
    setStage(0);
    setMaxStage(0);
    setValue('cpf', '');
  };

  const handleStage = () => {
    var hasError = false
    if (stage === 1) {
      if (!watch('name')) { setValue('name', { type: 'focus', message: 'Informe nome do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('cpf')) { setValue('cpf', { type: 'focus', message: 'Informe o CPF do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('birthdate')) { setValue('birthdate', { type: 'focus', message: 'Informe a data de nascimento do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('gender')) { setValue('gender', { type: 'focus', message: 'Informe o gênero do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('phoneNumber')) { setValue('phoneNumber', { type: 'focus', message: 'Informe telefone do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('email')) { setValue('email', { type: 'focus', message: 'Informe o email do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('maritalStatus')) { setValue('maritalStatus', { type: 'focus', message: 'Informe estado civil do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('motherName')) { setValue('motherName', { type: 'focus', message: 'Informe o nome da mãe do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!hasError) {
        setStage(stage + 1);
        if (stage === maxStage) setMaxStage(maxStage + 1);
      }
    }

    if (stage === 2) {
      if (!watch('type')) { setValue('type', { type: 'focus', message: 'Informe tipo de documento do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('numberDocument')) { setValue('numberDocument', { type: 'focus', message: 'Informe o nº do documento do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('issuingState')) { setValue('issuingState', { type: 'focus', message: 'Informe a UF de emissão do documento do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('issuingAuthority')) { setValue('issuingAuthority', { type: 'focus', message: 'Informe o órgão emissor do documento do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('issueDate')) { setValue('issueDate', { type: 'focus', message: 'Informe a data de emissão do documento do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!hasError) {
        setStage(stage + 1);
        if (stage === maxStage) setMaxStage(maxStage + 1);
      }
    }

    if (stage === 3) {
      if (!watch('zipCode')) { setValue('zipCode', { type: 'focus', message: 'Informe o CEP do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('street')) { setValue('street', { type: 'focus', message: 'Informe a Rua do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('number')) { setValue('number', { type: 'focus', message: 'Informe o nº do endereço do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('neighborhood')) { setValue('neighborhood', { type: 'focus', message: 'Informe o bairro do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('city')) { setValue('city', { type: 'focus', message: 'Informe a cidade do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('state')) { setValue('state', { type: 'focus', message: 'Informe o estado do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!hasError) {
        setStage(stage + 1);
        if (stage === maxStage) setMaxStage(maxStage + 1);
      }
    }

    if (stage === 4) {
      if (!watch('bankCode')) { setValue('bankCode', { type: 'focus', message: 'Informe o Banco do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('accountType')) { setValue('accountType', { type: 'focus', message: 'Informe o tipo de conta do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('accountNumber')) { setValue('accountNumber', { type: 'focus', message: 'Informe o nº da conta do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('accountDigit')) { setValue('accountDigit', { type: 'focus', message: 'Informe o digito da conta do cliente.' }, { shouldFocus: true }); hasError = true }
      if (!watch('branchNumber')) { setValue('branchNumber', { type: 'focus', message: 'Informe o código da agência do cliente.' }, { shouldFocus: true }); hasError = true }

      if (!hasError) {
        setStage(stage + 1);
        if (stage === maxStage) setMaxStage(maxStage + 1);
      }
    }
  }

  const clickStage = (stg) => {
    if (stg > maxStage) return

    setStage(stg);

  }

  const sendProposal = async () => {
    try {
      if (!financialId) {
        toast.error('É necessário realizar uma simulação primeiro');
        return;
      }

      setSendingProposal(true);
      const data = {
        feeScheduleId: 0,
        financialId: financialId,
        borrower: {
          name: getValues('name'),
          cpf: getValues('cpf'),
          birthdate: getValues('birthdate'),
          gender: getValues('gender'),
          phoneNumber: getValues('phoneNumber'),
          email: getValues('email'),
          maritalStatus: getValues('maritalStatus'),
          nationality: 'brasileiro',
          motherName: getValues('motherName'),
          pep: false
        },
        document: {
          type: getValues('type'),
          number: getValues('numberDocument'),
          issuingState: getValues('issuingState'),
          issuingAuthority: getValues('issuingAuthority'),
          issueDate: getValues('issueDate')
        },
        address: {
          zipCode: getValues('zipCode'),
          street: getValues('street'),
          number: getValues('number'),
          neighborhood: getValues('neighborhood'),
          city: getValues('city'),
          state: getValues('state'),
        },
        disbursementBankAccount: {
          bankCode: getValues('bankCode'),
          accountType: getValues('accountType'),
          accountNumber: getValues('accountNumber'),
          accountDigit: getValues('accountDigit'),
          branchNumber: getValues('branchNumber'),
        }
      };

      const result = await createProposal(data);
      
      // Mostra mensagens de sucesso inicial
      toast.success(result.mensagem);
      if (result.destaque) {
        toast.info(result.destaque, {
          autoClose: 6000
        });
      }

      // Busca o link de formalização
      if (result.contract_number) {
        try {
          const linkResult = await getProposalLink(result.contract_number);
          if (linkResult.status) {
            setFormalizationLink(linkResult.status);
            
            // Mostra toast com o link de formalização
            toast.info(
              <div>
                <p>Clique no link abaixo para formalizar sua proposta:</p>
                <a 
                  href={linkResult.status}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700 underline"
                >
                  Formalizar Proposta
                </a>
              </div>,
              {
                autoClose: false,
                closeOnClick: false,
              }
            );
          }
        } catch (linkError) {
          console.error('Error getting formalization link:', linkError);
          toast.warning('Proposta criada com sucesso, mas não foi possível obter o link de formalização. Por favor, entre em contato com o suporte.');
        }
      }
      
      // Limpa todos os estados apenas após sucesso no envio
      setSimulationData(null);
      setFinancialId(null);
      setStage(6);
      setMaxStage(6);
      setCpf('');
      setValue('cpf', '');
      setValue('name', '');
      setValue('birthdate', '');
      setValue('gender', '');
      setValue('phoneNumber', '');
      setValue('email', '');
      setValue('maritalStatus', '');
      setValue('motherName', '');
      setValue('type', '');
      setValue('numberDocument', '');
      setValue('issuingState', '');
      setValue('issuingAuthority', '');
      setValue('issueDate', '');
      setValue('zipCode', '');
      setValue('street', '');
      setValue('number', '');
      setValue('neighborhood', '');
      setValue('city', '');
      setValue('state', '');
      setValue('bankCode', '');
      setValue('accountType', '');
      setValue('accountNumber', '');
      setValue('accountDigit', '');
      setValue('branchNumber', '');
      
    } catch (error) {
      // Em caso de erro mantém todos os dados para correção
      toast.error(error.message, {
        autoClose: 8000
      });
      
      if (error.detail && error.message !== error.detail) {
        toast.error(error.detail, {
          autoClose: 8000
        });
      }
    } finally {
      setSendingProposal(false);
    }
  };

  const formatCPF = (value) => {
    const numbers = value.replace(/\D/g, '');
    setCpf(numbers);
  };

  const activeBanks = banks?.active_banks || [];

  return (
    <div className="flex flex-col bg-white shadow-md rounded-lg p-6 gap-6">
      {stage < 6 &&
        <div className='flex justify-center  gap-2'>
          <div className={`flex w-full h-10 rounded-ful justify-center items-center text-sm cursor-pointer
          ${stage === 0 && !loading
              ? 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2 text-white font-bold'
              : 'bg-gray-100 '
            }`}
            onClick={() => clickStage(0)}
          >
            Simulação
          </div>

          <div className={`flex w-full h-10 rounded-ful justify-center items-center text-sm cursor-pointer
          ${stage === 1 && !loading
              ? 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2 text-white font-bold'
              : 'bg-gray-100 '
            }`}
            onClick={() => clickStage(1)}>
            Dados pessoais
          </div>

          <div className={`flex w-full h-10 rounded-ful justify-center items-center text-sm cursor-pointer
          ${stage === 2 && !loading
              ? 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2 text-white font-bold'
              : 'bg-gray-100 '
            }`}
            onClick={() => clickStage(2)}
          >
            Documento
          </div>

          <div className={`flex w-full h-10 rounded-ful justify-center items-center text-sm cursor-pointer
          ${stage === 3 && !loading
              ? 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2 text-white font-bold'
              : 'bg-gray-100 '
            }`}
            onClick={() => clickStage(3)}
          >
            Endereço
          </div>

          <div className={`flex w-full h-10 rounded-ful justify-center items-center text-sm cursor-pointer
          ${stage === 4 && !loading
              ? 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2 text-white font-bold'
              : 'bg-gray-100 '
            }`}
            onClick={() => clickStage(4)}
          >
            Dados bancarios
          </div>

          <div className={`flex w-full h-10 rounded-ful justify-center items-center text-sm cursor-pointer
          ${stage === 5 && !loading
              ? 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2 text-white font-bold'
              : 'bg-gray-100 '
            }`}
            onClick={() => clickStage(5)}
          >
            Revisão
          </div>
        </div>
      }
      {stage === 0 &&
        <div className="space-y-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="cpf" className="block text-sm font-medium text-gray-700">
                CPF
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="cpf"
                  value={cpf}
                  onChange={(e) => formatCPF(e.target.value)}
                  maxLength={11}
                  placeholder="Digite apenas números"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#19bc5a] focus:ring-[#19bc5a] sm:text-sm"
                  required
                  disabled={loading}
                />
              </div>
            </div>

            <div>
              <label htmlFor="bank" className="block text-sm font-medium text-gray-700">
                Banco
              </label>
              <div className="mt-1">
                <select
                  id="bank"
                  value={selectedBank?.code || ''}
                  onChange={(e) => {
                    const bank = activeBanks.find(b => b.code === e.target.value);
                    onBankChange(bank || null);
                  }}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#19bc5a] focus:ring-[#19bc5a] sm:text-sm"
                  disabled={loading || loadingBanks}
                >
                  <option value="">Selecione um banco</option>
                  {activeBanks.map((bank) => (
                    <option key={bank.code} value={bank.code}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {!simulationData && !simulationError && (
              <button
                type="submit"
                disabled={loading || cpf.length !== 11 || !selectedBank}
                className={`w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                  loading || cpf.length !== 11 || !selectedBank
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2'
                }`}
              >
                {loading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Simulando...
                  </>
                ) : (
                  'Simular'
                )}
              </button>
            )}
          </form>

          {loading && (
            <div className="mt-4 p-4 bg-blue-50 rounded-lg border border-blue-200 animate-pulse">
              <div className="flex items-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <div>
                  <h3 className="text-lg font-medium text-blue-800">Processando Simulação</h3>
                  <p className="text-sm text-blue-600">Aguarde enquanto calculamos as melhores condições para você...</p>
                </div>
              </div>
            </div>
          )}

          {simulationError && (
            <div className="mt-4 p-4 bg-red-50 rounded-lg border border-red-200">
              <h3 className="text-lg font-medium text-red-800 mb-2">Erro na Simulação</h3>
              <div className="space-y-2 text-sm text-red-700">
                <p>{simulationError}</p>
              </div>
              <div className="mt-4">
                <button
                  onClick={resetAllStates}
                  className="w-full py-2 px-4 border border-red-500 rounded-md shadow-sm text-sm font-medium text-red-500 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Tentar Novamente
                </button>
              </div>
            </div>
          )}

          {simulationData && (
            <div className="mt-4 p-4 bg-green-50 rounded-lg border border-green-200">
              <h3 className="text-lg font-medium text-green-800 mb-2">Resultado da Simulação</h3>
              <div className="space-y-2 text-sm text-green-700">
                <p>Valor Disponível: R$ {simulationData.total_released}</p>
                <p>Valor a Pagar: R$ {simulationData.total_to_pay}</p>
                <p>Taxa de Juros: {simulationData.interest_rate}</p>
                <p>IOF: {simulationData.iof_fee}</p>
                {simulationData.highlight && (
                  <p className="font-medium mt-2">{simulationData.highlight}</p>
                )}
              </div>
              <div className="flex space-x-4 mt-4">
                <button
                  onClick={handleContinueProposal}
                  className="flex-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#19bc5a]"
                >
                  Continuar com a Proposta
                </button>
                <button
                  onClick={handleNewSimulation}
                  className="flex-1 py-2 px-4 border border-[#19bc5a] rounded-md shadow-sm text-sm font-medium text-[#19bc5a] bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#19bc5a]"
                >
                  Nova Simulação
                </button>
              </div>
            </div>
          )}
        </div>
      }
      {stage === 1 &&
        <form className="space-y-4">
          {renderPersonalInfoFields()}
          <div>
            <button
              type="button"
              disabled={loading}
              onClick={() => handleStage()}
              className={`w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                loading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2'
              }`}
            >
              {loading ? 'Carregando...' : 'Avançar'}
            </button>
          </div>
        </form>
      }
      {stage === 2 &&
        <form className="space-y-4">
          {renderDocumentFields()}
          <div>
            <button
              type="button"
              disabled={loading}
              onClick={() => handleStage()}
              className={`w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                loading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2'
              }`}
            >
              {loading ? 'Carregando...' : 'Avançar'}
            </button>
          </div>
        </form>
      }
      {stage === 3 &&
        <form className="space-y-4">
          {renderAddressFields()}
          <div>
            <button
              type="button"
              disabled={loading}
              onClick={() => handleStage()}
              className={`w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                loading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2'
              }`}
            >
              {loading ? 'Carregando...' : 'Avançar'}
            </button>
          </div>
        </form>
      }
      {stage === 4 &&
        <form className="space-y-4">
          {renderBankFields()}
          <div>
            <button
              type="button"
              disabled={loading}
              onClick={() => handleStage()}
              className={`w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                loading
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2'
              }`}
            >
              {loading ? 'Carregando...' : 'Avançar'}
            </button>
          </div>
        </form>
      }
      {stage === 5 &&
        <form className="space-y-4">
          <div className='flex flex-col w-full'>
            <div className='flex w-full text-[#148f44] border-b border-[#a2c5af] bg-[#f3f7f5] p-2'>
              Dados Pessoais
            </div>
            <div className='grid grid-cols-3 w-full'>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Nome: </span>
                {watch('name')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>CPF': </span>
                {watch('cpf')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Data Nasc.: </span>
                {watch('birthdate')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Gênero:</span>
                {watch('gender')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Telefone:</span>
                {watch('phoneNumber')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'> Email:</span>
                {watch('email')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Estado Civil: </span>
                {watch('maritalStatus')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Nome da Mãe: </span>
                {watch('motherName')}
              </div>
            </div>

            <div className='flex w-full text-[#148f44] border-b border-[#a2c5af] bg-[#f3f7f5] p-2'>
              Documento
            </div>
            <div className='grid grid-cols-3 w-full'>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Tipo:</span>
                {watch('type')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Nº Doc.:</span>
                {watch('numberDocument')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Estado Emissão:</span>
                {watch('issuingState')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Órgão Emissor:</span>
                {watch('issuingAuthority')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Data emissão:</span>
                {watch('issueDate')}
              </div>
            </div>

            <div className='flex w-full text-[#148f44] border-b border-[#a2c5af] bg-[#f3f7f5] p-2'>
              Endereço
            </div>
            <div className='grid grid-cols-3 w-full'>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>CEP: </span>
                {watch('zipCode')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Rua: </span>
                {watch('street')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Nº: </span>
                {watch('number')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Bairro: </span>
                {watch('neighborhood')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Cidade: </span>
                {watch('city')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Estado: </span>
                {watch('state')}
              </div>
            </div>

            <div className='flex w-full text-[#148f44] border-b border-[#a2c5af] bg-[#f3f7f5] p-2'>
              Dados Bancários
            </div>
            <div className='grid grid-cols-3 w-full'>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Banco:</span>
                {watch('bankCode')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Tipo Conta:</span>
                {watch('accountType')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>N Conta:</span>
                {watch('accountNumber')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Digito Conta:</span>
                {watch('accountDigit')}
              </div>
              <div className='flex w-full py-3 px-2'>
                <span className='text-[#148f44] mr-2'>Código Agência:</span>
                {watch('branchNumber')}
              </div>
            </div>

          </div>

          <div className="mt-4 flex justify-between">
            <button
              onClick={() => setStage(4)}
              disabled={sendingProposal}
              className={`px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#19bc5a] ${
                sendingProposal ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              Voltar
            </button>
            <button
              onClick={sendProposal}
              disabled={sendingProposal}
              className={`px-4 py-2 text-sm font-medium text-white bg-[#19bc5a] border border-transparent rounded-md hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#19bc5a] ${
                sendingProposal ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {sendingProposal ? (
                <>
                  <span className="inline-block mr-2">Enviando proposta</span>
                  <svg className="inline-block animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </>
              ) : (
                'Enviar proposta'
              )}
            </button>
          </div>
        </form>
      }
      {stage === 6 &&
        <>

          <div className='flex flex-col w-full'>
            <div className='flex w-full text-[#148f44] border-b border-[#a2c5af] bg-[#f3f7f5] py-8 text-4xl justify-center'>
              Proposta criada com sucesso!
            </div>
          </div>

        </>
      }
      {stage === 6 && formalizationLink && (
        <div className="bg-green-50 border-l-4 border-green-400 p-4 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-green-700">
                Sua proposta foi enviada com sucesso! Para finalizar o processo, clique no botão abaixo para formalizar sua proposta:
              </p>
              <div className="mt-4">
                <a
                  href={formalizationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Formalizar Proposta
                  <svg className="ml-2 -mr-1 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
