const BASE_URL = process.env.REACT_APP_API_URL;


const handleResponse = async (response) => {
  if (!response.ok) {
    const text = await response.text();
    let error;
    try {
      error = JSON.parse(text);
    } catch {
      error = { detail: text };
    }
    throw new Error(error.detail || 'Erro na requisição');
  }
  return response.json();
};

const simulationService = {
  // GET /api/v1/banks - Lista todos os bancos disponíveis
  async getBanks() {
    const response = await fetch(`${BASE_URL}/banks`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });
    return handleResponse(response);
  },

  // GET /api/v1/simulation/{cpf} - Simula FGTS
  async simulate(cpf, bank = null) {
    const url = new URL(`${BASE_URL}/simulation/${cpf}`);
    if (bank) {
      url.searchParams.append('bank', bank);
    }
    
    console.log(url)
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      }
    });
    console.log(response)
    return handleResponse(response);
  },

  // GET /api/v1/simulation/{cpf}/history - Histórico de simulações
  async getHistory(cpf, bank = null) {
    const url = new URL(`${BASE_URL}/simulation/${cpf}/history`);
    if (bank) {
      url.searchParams.append('bank', bank);
    }
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });
    return handleResponse(response);
  },

  // GET /api/v1/simulation/history/all - Histórico de simulações com paginação
  async getAllSimulations(page = 1, perPage = 10, bank = null) {
    try {
      const url = new URL(`${BASE_URL}/simulation/history/all`);
      url.searchParams.append('page', page);
      url.searchParams.append('per_page', perPage);
      if (bank) {
        url.searchParams.append('bank', bank);
      }
      
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });
      return handleResponse(response);
    } catch (error) {
      console.error('Erro ao buscar histórico:', error);
      throw error;
    }
  },

  // GET /api/v1/simulation/cpfs - Lista CPFs únicos
  async getUniqueCpfs() {
    const response = await fetch(`${BASE_URL}/simulation/cpfs`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    });
    return handleResponse(response);
  },
};

export default simulationService;
