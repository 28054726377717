import { useEffect, useState } from 'react';

export function UploadNotification({ show, onClose, result, error }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      setIsFading(false);
      
      // Inicia o fade out após 27 segundos (3 segundos antes de fechar)
      const fadeTimer = setTimeout(() => {
        setIsFading(true);
      }, 27000);

      // Fecha após 30 segundos
      const closeTimer = setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, 30000);

      return () => {
        clearTimeout(fadeTimer);
        clearTimeout(closeTimer);
      };
    }
  }, [show, onClose]);

  if (!isVisible) return null;

  const baseClasses = `
    mt-4 p-4 rounded-lg w-full transition-opacity duration-300
    ${isFading ? 'opacity-0' : 'opacity-100'}
  `;

  if (error) {
    return (
      <div className={`${baseClasses} bg-red-50 border border-red-200`}>
        <div className="flex flex-col">
          <h4 className="font-semibold text-red-700 text-center border-b border-red-200 pb-2 mb-2">
            Erro no Upload
          </h4>
          <p className="text-sm text-red-600 text-center">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`${baseClasses} bg-green-50 border border-green-200`}>
      <div className="flex flex-col">
        <h4 className="font-semibold text-green-700 text-center border-b border-green-200 pb-2 mb-4">
          Upload Concluído com Sucesso
        </h4>
        <div className="grid grid-cols-3 gap-6 text-center">
          <div className="flex flex-col items-center">
            <p className="text-sm font-medium text-green-600 mb-1">Total Processado</p>
            <p className="text-2xl font-semibold text-green-700">{result?.total_processed || 0}</p>
          </div>
          <div className="flex flex-col items-center border-x border-green-200 px-4">
            <p className="text-sm font-medium text-green-600 mb-1">Sucessos</p>
            <p className="text-2xl font-semibold text-green-700">{result?.success_count || 0}</p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-sm font-medium text-green-600 mb-1">Erros</p>
            <p className="text-2xl font-semibold text-green-700">{result?.error_count || 0}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
