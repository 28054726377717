import { useState } from 'react';
import { Database, Users, MessageSquare, BarChart, Calculator, Briefcase, CircleDollarSign, UserCheck } from 'lucide-react';
import { FileUpload } from './FileUpload';
import { Toaster, toast } from 'react-hot-toast';
import { Monitor } from './Monitor';
import { HistoricoDeConversas } from './HistoricoDeConversas';
import { SimulationPage } from './simulation/SimulationPage';
import { Pipeline } from './Pipeline/Pipeline';
import { documentService, customerService } from '../services/api';
import { UploadNotification } from './UploadNotification';
import { Header } from './Header';
import { ProposalPage } from './proposal/ProposalPage';
import TrafficLeads from './TrafficLeads';

export function PainelAdmin() {
  const [abaAtiva, setAbaAtiva] = useState('monitor');
  const [uploading, setUploading] = useState(false);
  const [uploadResult, setUploadResult] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const notifyConfig = {
    success: {
      duration: 4000,
      position: 'top-right',
      style: {
        background: '#f0fdf4',
        color: '#166534',
        padding: '16px',
        borderRadius: '8px',
        border: '1px solid #dcfce7',
      },
    },
    error: {
      duration: 5000,
      position: 'top-right',
      style: {
        background: '#fef2f2',
        color: '#991b1b',
        padding: '16px',
        borderRadius: '8px',
        border: '1px solid #fee2e2',
      }
    }
  };

  const handleDocumentUpload = async (files) => {
    try {
      if (!files || files.length === 0) {
        alert('Por favor, selecione pelo menos um arquivo');
        return;
      }

      setUploading(true);
      const loadingToast = toast.loading('Processando documentos...', {
        position: 'top-right'
      });

      // Converter FileList para Array se necessário
      const filesArray = Array.from(files);

      // Validar tipos de arquivo
      const allowedExtensions = ['.pdf', '.doc', '.docx', '.txt'];
      const invalidFiles = filesArray.filter(file => {
        const extension = '.' + file.name.split('.').pop().toLowerCase();
        return !allowedExtensions.includes(extension);
      });

      if (invalidFiles.length > 0) {
        toast.dismiss(loadingToast);
        throw new Error(`Arquivos não permitidos: ${invalidFiles.map(f => f.name).join(', ')}`);
      }
      await documentService.upload(filesArray);
      toast.dismiss(loadingToast);
      toast.success('Documentos enviados com sucesso!', notifyConfig.success);


    } catch (error) {
      console.error('Erro no upload:', error);
      alert(error.message || 'Erro ao enviar documentos');
    } finally {
      setUploading(false);
    }
  };

  const handleCustomerUpload = async (files) => {
    try {
      if (!files || files.length !== 1) {
        setUploadError('Por favor, selecione apenas um arquivo CSV');
        setShowNotification(true);
        return;
      }

      setUploading(true);
      setUploadError(null);
      setUploadResult(null);
      const file = files[0];

      if (!file.name.endsWith('.csv')) {
        throw new Error('Por favor, selecione um arquivo CSV válido');
      }

      const result = await customerService.uploadCSV(file);
      setUploadResult(result);
      setShowNotification(true);

    } catch (error) {
      console.error('Erro no upload:', error);
      setUploadError(error.message || 'Erro ao importar clientes');
      setShowNotification(true);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <Toaster />
      <div className="max-w-7xl mx-auto px-4 py-6">
        <nav className="flex space-x-4 mb-6">
          <NavButton
            active={abaAtiva === 'monitor'}
            onClick={() => setAbaAtiva('monitor')}
            icon={<BarChart className="w-5 h-5" />}
            text="Monitor"
          />
          <NavButton
            active={abaAtiva === 'historico'}
            onClick={() => setAbaAtiva('historico')}
            icon={<MessageSquare className="w-5 h-5" />}
            text="Histórico"
          />
          <NavButton
            active={abaAtiva === 'simulacao'}
            onClick={() => setAbaAtiva('simulacao')}
            icon={<CircleDollarSign className="w-5 h-5" />}
            text="Consulta de Saldo"
          />
          <NavButton
            active={abaAtiva === 'proposta'}
            onClick={() => setAbaAtiva('proposta')}
            icon={<Calculator className="w-5 h-5" />}
            text="Proposta"
          />
          <NavButton
            active={abaAtiva === 'pipeline'}
            onClick={() => setAbaAtiva('pipeline')}
            icon={<Briefcase className="w-5 h-5" />}
            text="Esteira"
          />
          <NavButton
            active={abaAtiva === 'leads'}
            onClick={() => setAbaAtiva('leads')}
            icon={<UserCheck className="w-5 h-5" />}
            text="Leads"
          />
          <NavButton
            active={abaAtiva === 'upload'}
            onClick={() => setAbaAtiva('upload')}
            icon={<Database className="w-5 h-5" />}
            text="Upload"
          />
          <NavButton
            active={abaAtiva === 'clientes'}
            onClick={() => setAbaAtiva('clientes')}
            icon={<Users className="w-5 h-5" />}
            text="Clientes"
          />
        </nav>

        <div className="bg-white rounded-lg shadow p-6">
          {abaAtiva === 'monitor' && <Monitor />}
          {abaAtiva === 'upload' && (
            <div className="space-y-6">
              <div className="border-b pb-4">
                <h2 className="text-xl font-semibold text-[#515151] mb-2">Gestão de Memória do Assistente</h2>
                <p className="text-gray-600">
                  Faça upload de documentos para enriquecer a base de conhecimento do assistente virtual.
                  Nossa tecnologia RAG (Retrieval-Augmented Generation) processará automaticamente os documentos,
                  permitindo respostas mais precisas e contextualizadas.
                </p>
              </div>

              <div className="bg-[#19bc5a]/5 rounded-lg p-4 mb-4">
                <h3 className="text-[#515151] font-medium mb-2">Documentos Aceitos</h3>
                <ul className="text-gray-600 space-y-1 text-sm">
                  <li>• Documentos de texto (.pdf, .doc, .docx, .txt)</li>
                  <li>• Tamanho máximo por arquivo: 10MB</li>
                  <li>• Documentos preferencialmente em português</li>
                </ul>
              </div>

              <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                <h3 className="text-[#515151] font-medium mb-2">Recomendações</h3>
                <ul className="text-gray-600 space-y-1 text-sm">
                  <li>• Documentos com informações sobre FGTS e processos relacionados</li>
                  <li>• Manuais de procedimentos e políticas internas</li>
                  <li>• FAQs e documentos de suporte ao cliente</li>
                  <li>• Atualizações sobre legislação e normativas</li>
                </ul>
              </div>

              <FileUpload
                onUpload={handleDocumentUpload}
                acceptedTypes=".pdf,.doc,.docx,.txt"
                multiple={true}
                disabled={uploading}
              />
            </div>
          )}
          {abaAtiva === 'historico' && <HistoricoDeConversas />}
          {abaAtiva === 'simulacao' && <SimulationPage />}
          {abaAtiva === 'proposta' && <ProposalPage />}
          {abaAtiva === 'pipeline' && <Pipeline />}
          {abaAtiva === 'leads' && <TrafficLeads />}
          {abaAtiva === 'clientes' && (
            <div className="space-y-6">
              <div className="border-b pb-4">
                <h2 className="text-xl font-semibold text-[#515151] mb-2">Importação de Base de Leads</h2>
                <p className="text-gray-600">
                  Faça upload do seu arquivo CSV contendo a base de potenciais clientes para análise de FGTS.
                  Nosso sistema processará automaticamente os dados e iniciará o atendimento personalizado.
                </p>
              </div>
              <div className="bg-[#19bc5a]/5 rounded-lg p-4 mb-4">
                <h3 className="text-[#515151] font-medium mb-2">Requisitos do Arquivo</h3>
                <ul className="text-gray-600 space-y-1 text-sm">
                  <li>• Formato aceito: CSV (valores separados por vírgula)</li>
                  <li>• Campos necessários: nome, telefone, CPF</li>
                  <li>• Codificação recomendada: UTF-8</li>
                </ul>
              </div>
              <FileUpload
                onUpload={handleCustomerUpload}
                acceptedTypes=".csv"
                multiple={false}
              />
              <UploadNotification
                show={showNotification}
                onClose={() => setShowNotification(false)}
                result={uploadResult}
                error={uploadError}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function NavButton({ active, onClick, icon, text, disabled }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        flex items-center px-4 py-2 rounded-lg transition-colors
        ${active
          ? 'bg-[#19bc5a] text-white'
          : 'text-[#515151] hover:bg-gray-100'
        }
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
      `}
    >
      <span className="mr-2">{icon}</span>
      {text}
    </button>
  );
}