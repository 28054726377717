import React from 'react';

export const BankResults = ({ results }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);
  };

  return (
    <div className="space-y-4">
      {results.map((result, index) => (
        <div 
          key={index}
          className={`p-4 rounded-lg border ${
            result.success
              ? 'bg-green-50 border-green-200'
              : 'bg-red-50 border-red-200'
          }`}
        >
          <div className="flex items-start justify-between">
            <div>
              <h4 className="text-lg font-medium text-gray-900">
                {result.bank_name}
              </h4>
              {result.success ? (
                <div className="mt-2">
                  <p className="text-2xl font-semibold text-[#19bc5a]">
                    {formatCurrency(result.available_amount)}
                  </p>
                </div>
              ) : (
                <p className="mt-2 text-sm text-red-600">
                  {result.error_message}
                </p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
