import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

class APIError extends Error {
  constructor(error) {
    const message = error.response?.data?.detail || 
                   error.response?.data?.message || 
                   error.message;
    super(message);
    this.name = 'APIError';
    this.detail = error.response?.data?.detail;
    this.data = error.response?.data;
  }
}

export const simulateCredit = async (cpf, feeScheduleId = 0) => {
  try {
    console.log('Sending simulation request:', { cpf, feeScheduleId });
    const response = await axios.post(`${API_BASE_URL}/vctex/simulation`, {
      cpf,
      fee_schedule_id: feeScheduleId
    });
    console.log('Simulation response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Simulation error:', error.response?.data || error);
    throw new APIError(error);
  }
};

export const createProposal = async (proposalData) => {
  try {
    console.log('Sending proposal:', proposalData);
    const response = await axios.post(`${API_BASE_URL}/vctex/proposal`, proposalData);
    console.log('Proposal complete response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Proposal error:', error.response?.data || error);
    throw new APIError(error);
  }
};

export const getProposalLink = async (contractNumber) => {
  try {
    console.log('Getting proposal link for contract:', contractNumber);
    const response = await axios.post(`${API_BASE_URL}/vctex/proposal/link`, {
      contract_number: contractNumber
    });
    console.log('Proposal link response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Proposal link error:', error.response?.data || error);
    throw new APIError(error);
  }
};
