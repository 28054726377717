import { useState, useEffect, useCallback } from 'react';
import { ListFilter, RefreshCcw, Bot, UserRound, X, Link } from 'lucide-react';
import { chatService, vctexService } from '../../services/api';
import { formatCurrency } from '../../utils/formatters';
import { Toaster, toast } from 'react-hot-toast';

export function Pipeline() {
  const [pipelineData, setPipelineData] = useState({ items: [], total: 0, page: 1, pages: 1 });
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFilter, setActiveFilter] = useState(null);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [cpfSearch, setCpfSearch] = useState('');
  const [searchTimeout, setSearchTimeout] = useState(null);

  const loadPipelineData = useCallback(async (page = 1) => {
    try {
      setLoading(true);
      const skip = (page - 1) * 20;
      const data = await chatService.getPipelineData(skip, cpfSearch);
      
      const filteredItems = activeFilter 
        ? data.items.filter(item => item.send_by?.toLowerCase() === activeFilter)
        : data.items;
      
      setPipelineData({
        ...data,
        items: filteredItems,
        total: filteredItems.length,
        pages: Math.ceil(filteredItems.length / 20)
      });
    } catch (error) {
      toast.error('Erro ao carregar dados da esteira');
      console.error('Erro:', error);
    } finally {
      setLoading(false);
    }
  }, [activeFilter, cpfSearch]);

  useEffect(() => {
    loadPipelineData(currentPage);
  }, [currentPage, loadPipelineData]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCpfSearch = (value) => {
    setCpfSearch(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        loadPipelineData(1);
      }, 500)
    );
  };

  const getSendByIcon = (sendBy) => {
    const type = sendBy?.toLowerCase();
    if (type === 'ia') {
      return (
        <div className="relative flex items-center">
          <div className="group">
            <Bot className="w-5 h-5 text-blue-500" />
            <div className="invisible group-hover:visible absolute left-full ml-2 top-1/2 -translate-y-1/2
                          bg-gray-800 text-white text-xs rounded px-2 py-1 whitespace-nowrap z-50
                          before:content-[''] before:absolute before:right-full before:top-1/2 before:-translate-y-1/2
                          before:border-4 before:border-transparent before:border-r-gray-800">
              Enviado através da IA
            </div>
          </div>
        </div>
      );
    }
    if (type === 'manual') {
      return (
        <div className="relative flex items-center">
          <div className="group">
            <UserRound className="w-5 h-5 text-green-500" />
            <div className="invisible group-hover:visible absolute left-full ml-2 top-1/2 -translate-y-1/2
                          bg-gray-800 text-white text-xs rounded px-2 py-1 whitespace-nowrap z-50
                          before:content-[''] before:absolute before:right-full before:top-1/2 before:-translate-y-1/2
                          before:border-4 before:border-transparent before:border-r-gray-800">
              Enviado por operador
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const handleFormalizationLink = async (contractNumber) => {
    try {
      const link = await vctexService.getFormalizationLink(contractNumber);
      if (link) {
        toast.success('Link de formalização recuperado com sucesso!');
        window.open(link, '_blank');
      } else {
        toast.error('Link de formalização ainda não disponível');
      }
    } catch (error) {
      toast.error(error.message || 'Erro ao recuperar link de formalização');
      console.error('Erro:', error);
    }
  };

  const FilterMenu = () => (
    <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
      <div className="py-1">
        <button
          onClick={() => {
            setActiveFilter(null);
            setShowFilterMenu(false);
          }}
          className={`${
            activeFilter === null ? 'bg-gray-100' : ''
          } w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center justify-between`}
        >
          <span>Todos</span>
          {activeFilter === null && <span className="text-blue-500">✓</span>}
        </button>
        <button
          onClick={() => {
            setActiveFilter('ia');
            setShowFilterMenu(false);
          }}
          className={`${
            activeFilter === 'ia' ? 'bg-gray-100' : ''
          } w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center justify-between`}
        >
          <div className="flex items-center gap-2">
            <Bot className="w-4 h-4" />
            <span>IA</span>
          </div>
          {activeFilter === 'ia' && <span className="text-blue-500">✓</span>}
        </button>
        <button
          onClick={() => {
            setActiveFilter('manual');
            setShowFilterMenu(false);
          }}
          className={`${
            activeFilter === 'manual' ? 'bg-gray-100' : ''
          } w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center justify-between`}
        >
          <div className="flex items-center gap-2">
            <UserRound className="w-4 h-4" />
            <span>Manual</span>
          </div>
          {activeFilter === 'manual' && <span className="text-blue-500">✓</span>}
        </button>
      </div>
    </div>
  );

  return (
    <div className="max-w-[98vw] mx-auto px-4 sm:px-6 lg:px-8">
      <Toaster />
      
      {/* Cabeçalho */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-4 items-center">
          <div className="relative">
            <input
              type="text"
              placeholder="Buscar por CPF..."
              value={cpfSearch}
              onChange={(e) => handleCpfSearch(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:border-transparent"
            />
          </div>
          <div className="relative">
            <button
              onClick={() => setShowFilterMenu(!showFilterMenu)}
              className="px-4 py-2 bg-[#19bc5a] text-white rounded hover:bg-[#19bc5a]/90 transition-colors flex items-center gap-2"
            >
              <ListFilter className="w-4 h-4" />
              Filtrar
              {activeFilter && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveFilter(null);
                    setShowFilterMenu(false);
                  }}
                  className="ml-2 hover:bg-[#19bc5a]/80 rounded-full p-1"
                >
                  <X className="w-3 h-3" />
                </button>
              )}
            </button>
            {showFilterMenu && <FilterMenu />}
          </div>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => loadPipelineData(currentPage)}
            className="px-4 py-2 bg-[#19bc5a] text-white rounded hover:bg-[#19bc5a]/90 transition-colors flex items-center gap-2"
          >
            <RefreshCcw className="w-4 h-4" />
            Atualizar
          </button>
        </div>
      </div>

      {/* Tabela */}
      <div className="mt-8 flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Origem
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Nº Contrato
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cliente
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      CPF
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Valor Liberado
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Valor a Pagar
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Taxa
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      IOF
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Link de Formalização
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan="9" className="px-6 py-4 text-center">
                        <div className="flex justify-center items-center">
                          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                        </div>
                      </td>
                    </tr>
                  ) : pipelineData.items.length === 0 ? (
                    <tr>
                      <td colSpan="9" className="px-6 py-4 text-center text-gray-500">
                        Nenhuma proposta encontrada
                      </td>
                    </tr>
                  ) : (
                    pipelineData.items.map((item, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          {getSendByIcon(item.send_by)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.contract_number}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.customer_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.cpf}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {formatCurrency(item.simulation.total_released)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {formatCurrency(item.simulation.total_to_pay)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.simulation.interest_rate}%
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {formatCurrency(item.simulation.iof_fee)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          <button
                            onClick={() => handleFormalizationLink(item.contract_number)}
                            className="text-blue-600 hover:text-blue-800 p-2 rounded-full hover:bg-blue-100 transition-colors"
                            title="Obter link de formalização"
                          >
                            <Link size={20} />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Paginação */}
        {pipelineData.pages > 1 && (
          <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Anterior
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === pipelineData.pages}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Próxima
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Mostrando <span className="font-medium">{((currentPage - 1) * 20) + 1}</span> até{' '}
                  <span className="font-medium">
                    {Math.min(currentPage * 20, pipelineData.total)}
                  </span>{' '}
                  de <span className="font-medium">{pipelineData.total}</span> resultados
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    Anterior
                  </button>
                  {[...Array(pipelineData.pages)].map((_, i) => (
                    <button
                      key={i}
                      onClick={() => handlePageChange(i + 1)}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${
                        currentPage === i + 1
                          ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                          : 'bg-white text-gray-500 hover:bg-gray-50'
                      }`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === pipelineData.pages}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    Próxima
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
