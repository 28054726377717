import { useState, useEffect, useCallback } from 'react';
import { Search } from 'lucide-react';
import { chatService } from '../services/api';

export function HistoricoDeConversas() {
  const [conversas, setConversas] = useState([]);
  const [busca, setBusca] = useState('');
  const [loading, setLoading] = useState(false);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [conversaAtual, setConversaAtual] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 5;

  // Formata o número de telefone removendo caracteres especiais
  const formatarTelefone = (numero) => {
    return numero.replace(/\D/g, '');
  };

  // Usando useCallback para evitar o warning do useEffect
  const carregarConversas = useCallback(async () => {
    try {
      setLoading(true);
      // Se a busca contiver apenas números, formata como telefone
      const termoBusca = /^\d+$/.test(busca.trim()) ? formatarTelefone(busca) : busca;
      const response = await chatService.searchChats(termoBusca, paginaAtual, itemsPerPage);
      setConversas(response.items || []);
      setTotalItems(response.total || 0);
    } catch (error) {
      console.error('Erro ao carregar conversas:', error);
      setConversas([]);
      setTotalItems(0);
    } finally {
      setLoading(false);
    }
  }, [busca, paginaAtual]);

  useEffect(() => {
    carregarConversas();
  }, [carregarConversas]);

  const handleBusca = async (e) => {
    e.preventDefault();
    setPaginaAtual(1); // Reset para primeira página ao buscar
    await carregarConversas();
  };

  // Calcula o total de páginas
  const totalPages = Math.max(1, Math.ceil(totalItems / itemsPerPage));

  // Gera array com números das páginas a serem exibidas
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    
    if (totalPages <= maxVisiblePages) {
      // Se tiver 5 ou menos páginas, mostra todas
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Sempre mostra a primeira página
      pageNumbers.push(1);
      
      let startPage = Math.max(2, paginaAtual - 1);
      let endPage = Math.min(totalPages - 1, paginaAtual + 1);
      
      // Ajusta para sempre mostrar 3 páginas no meio
      if (paginaAtual <= 2) {
        endPage = 4;
      } else if (paginaAtual >= totalPages - 1) {
        startPage = totalPages - 3;
      }
      
      // Adiciona ... após primeira página se necessário
      if (startPage > 2) {
        pageNumbers.push('...');
      }
      
      // Adiciona páginas do meio
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      
      // Adiciona ... antes da última página se necessário
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      
      // Sempre mostra a última página
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  const handlePageChange = (page) => {
    if (page !== '...' && page !== paginaAtual) {
      setPaginaAtual(page);
    }
  };

  const verConversaDetalhada = async (sessionId) => {
    try {
      const conversa = await chatService.getChatConversation(sessionId);
      // Inverte a ordem das mensagens antes de atualizar o estado
      conversa.messages = conversa.messages.reverse();
      setConversaAtual(conversa);
    } catch (error) {
      console.error('Erro ao carregar conversa:', error);
    }
  };

  const formatarDataBrasilia = (dataUTC) => {
    // Converte a string UTC para objeto Date
    const dataOriginal = new Date(dataUTC);
    
    // Ajusta o offset para Brasília (-3 horas)
    const offsetBrasilia = -3;
    const dataAjustada = new Date(dataOriginal.getTime() + (offsetBrasilia * 60 * 60 * 1000));
    
    return dataAjustada.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-[#515151]">Histórico de Conversas</h2>
        <form onSubmit={handleBusca} className="flex flex-col gap-1">
          <div className="flex gap-2">
            <div className="relative">
              <Search className="w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-[#515151]" />
              <input
                type="text"
                placeholder="Buscar por nome ou telefone..."
                value={busca}
                onChange={(e) => setBusca(e.target.value)}
                className="pl-10 pr-4 py-2 border rounded-lg focus:ring-[#19bc5a] focus:border-[#19bc5a] text-[#515151] min-w-[300px]"
              />
            </div>
            <button
              type="submit"
              className="px-4 py-2 bg-[#19bc5a] text-white rounded-lg hover:bg-[#15a34d] transition-colors"
            >
              Buscar
            </button>
          </div>
          <span className="text-xs text-gray-500">
            Digite o número de telefone ou nome do cliente para buscar
          </span>
        </form>
        <button
          onClick={carregarConversas}
          className="px-4 py-2 bg-[#19bc5a] text-white rounded hover:bg-[#19bc5a]/90 transition-colors flex items-center gap-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
          </svg>
          Atualizar
        </button>
      </div>

      {loading ? (
        <div className="text-center py-8 text-[#515151]">Carregando conversas...</div>
      ) : (
        <>
          {!loading && conversas.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              Nenhuma conversa encontrada.
            </div>
          )}

          {!loading && conversas.length > 0 && (
            <>
              <div className="grid grid-cols-1 gap-4 mb-6">
                {conversas.map((conversa) => (
                  <div 
                    key={conversa.session_id}
                    className="border rounded-lg p-4 hover:bg-gray-50 transition-colors"
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="font-semibold text-[#515151]">
                          {conversa.customer_name || 'Cliente sem nome'}
                        </h3>
                        <p className="text-sm text-gray-500">
                          Telefone: {conversa.session_id}
                        </p>
                      </div>
                      <span className="text-sm text-gray-500">
                        {formatarDataBrasilia(conversa.last_updated)}
                      </span>
                    </div>
                    <div className="mt-2 flex justify-between items-center">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        conversa.contract_number 
                          ? 'bg-[#19bc5a]/10 text-[#19bc5a]' 
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {conversa.contract_number ? 'Proposta Concluída' : 'Em Andamento'}
                      </span>
                      <button
                        onClick={() => verConversaDetalhada(conversa.session_id)}
                        className="px-3 py-1.5 bg-[#19bc5a] text-white rounded hover:bg-[#19bc5a]/90 transition-colors flex items-center gap-1.5"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                          <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                        </svg>
                        Monitorar
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              {/* Controles de Paginação */}
              <div className="mt-6 flex justify-center items-center gap-2">
                <button
                  onClick={() => handlePageChange(paginaAtual - 1)}
                  disabled={paginaAtual === 1}
                  className={`px-3 py-1 rounded ${
                    paginaAtual === 1
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-[#19bc5a] text-white hover:bg-[#19bc5a]/90'
                  }`}
                >
                  Anterior
                </button>
                
                <div className="flex items-center gap-1">
                  {getPageNumbers().map((page, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageChange(page)}
                      disabled={page === '...'}
                      className={`min-w-[32px] h-8 flex items-center justify-center rounded ${
                        page === paginaAtual
                          ? 'bg-[#19bc5a] text-white'
                          : page === '...'
                          ? 'bg-transparent text-gray-600 cursor-default'
                          : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }`}
                    >
                      {page}
                    </button>
                  ))}
                </div>

                <button
                  onClick={() => handlePageChange(paginaAtual + 1)}
                  disabled={paginaAtual === totalPages}
                  className={`px-3 py-1 rounded ${
                    paginaAtual === totalPages
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-[#19bc5a] text-white hover:bg-[#19bc5a]/90'
                  }`}
                >
                  Próxima
                </button>
              </div>

              {/* Informação sobre total de itens */}
              <div className="text-center mt-2 text-sm text-gray-500">
                Mostrando {Math.min(itemsPerPage, conversas.length)} de {totalItems} conversas
              </div>
            </>
          )}
        </>
      )}

      {/* Modal de Conversa Detalhada */}
      {conversaAtual && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[80vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-4 pb-3 border-b border-[#19bc5a]/20">
              <h3 className="text-lg font-semibold text-[#515151]">
                Conversa com {conversaAtual.customer_name || 'Cliente'}
              </h3>
              <button 
                onClick={() => setConversaAtual(null)}
                className="text-[#515151] hover:text-[#19bc5a] transition-colors"
              >
                ✕
              </button>
            </div>
            <div className="space-y-4">
              {conversaAtual.messages.map((message, index) => (
                <div 
                  key={index}
                  className={`p-3 rounded-lg ${
                    message.sender === 'Assistente'
                      ? 'bg-[#19bc5a]/10 ml-4 border border-[#19bc5a]/20'
                      : 'bg-gray-50 mr-4 border border-gray-200'
                  }`}
                >
                  <p className="text-sm font-semibold mb-1 text-[#515151]">{message.sender}</p>
                  <p className="text-[#515151]">{message.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}