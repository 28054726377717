import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL ,
  timeout: 30000,
});

// Adicionar interceptor para incluir token em todas as requisições
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const listTrafficLeads = async (page = 1, limit = 10) => {
  try {
    const skip = (page - 1) * limit;
    const response = await api.get(`/sessions/traffic/leads?skip=${skip}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao listar leads:', error);
    throw error;
  }
};
