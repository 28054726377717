import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
});

export const fetchAddressByCep = async (cep) => {
  try {
    const response = await api.get(`/cep/${cep}`);
    return response.data;
  } catch (error) {
    throw new Error('Erro ao buscar endereço pelo CEP');
  }
};
