import React, { useState } from 'react';
import { createLead } from '../services/leads';
import decoTechLogo from '../assets/decoTech-v1-horizon-white.png';
import vcTexLogo from '../assets/logovctexroxa.png';
import qrcode from '../assets/qrcode.jpeg';

const LeadForm = () => {
  const [formData, setFormData] = useState({
    phone: '',
    name: '',
    email: '',
    cpf: '',
    zip_code: ''
  });

  const [message, setMessage] = useState('');
  const [showQRCode, setShowQRCode] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createLead(formData);
      setShowQRCode(true);
      // Redirecionar após 10 segundos
      setTimeout(() => {
        window.location.href = 'https://w.app/FGTSnaconta';
      }, 10000);
    } catch (error) {
      setMessage('Ops! Algo deu errado. Tente novamente.');
      console.error('Erro:', error);
    }
  };

  if (showQRCode) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#19bc5a]/10 to-white px-4 py-6 md:py-12">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Redirecionando para WhatsApp...
          </h2>
          <p className="text-gray-600 mb-6">
            Se não for redirecionado automaticamente, escaneie o QR Code abaixo
          </p>
          <img 
            src={qrcode} 
            alt="QR Code WhatsApp" 
            className="mx-auto w-64 h-64 object-contain rounded-xl shadow-lg"
          />
          <a
            href="https://w.app/FGTSnaconta"
            className="mt-6 inline-block text-[#19bc5a] hover:underline"
          >
            Clique aqui para abrir o WhatsApp
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-start bg-gradient-to-b from-[#19bc5a]/10 to-white px-4 py-6 md:py-12">
      <div className="w-full max-w-md mx-auto">
        <div className="bg-white rounded-3xl shadow-xl overflow-hidden">
          <div className="px-6 pt-6 pb-4 bg-[#19bc5a]/5">
            <div className="flex items-center justify-center space-x-4 mb-4">
              <img src={decoTechLogo} alt="DecoTech Logo" className="h-10 object-contain" />
              <img src={vcTexLogo} alt="VcTex Logo" className="h-16 w-16 object-contain" />
            </div>
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-2">
              Antecipe seu FGTS agora! 💰
            </h1>
            <p className="text-center text-gray-600 mb-2 text-lg">
              Dinheiro na conta em até 24h
            </p>
            <div className="flex justify-center gap-2 mb-4">
              <span className="bg-[#19bc5a]/20 text-[#19bc5a] px-3 py-1 rounded-full text-sm font-medium">
                Sem consulta SPC/Serasa
              </span>
            </div>
          </div>

          <div className="px-6 py-4">
            {message && (
              <div className={`mb-6 p-4 rounded-2xl text-center ${
                message.includes('Ótimo')
                  ? 'bg-[#19bc5a]/10 text-[#19bc5a] border border-[#19bc5a]/20'
                  : 'bg-red-50 text-red-700 border border-red-200'
              }`}>
                {message}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Nome Completo</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  minLength={3}
                  placeholder="Digite seu nome completo"
                  className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-[#19bc5a] focus:ring-[#19bc5a] text-base py-3"
                />
              </div>

              <div>
                <label htmlFor="cpf" className="block text-sm font-medium text-gray-700 mb-1">CPF</label>
                <input
                  type="text"
                  id="cpf"
                  name="cpf"
                  value={formData.cpf}
                  onChange={handleChange}
                  required
                  minLength={11}
                  maxLength={11}
                  pattern="\d{11}"
                  placeholder="Digite seu CPF (apenas números)"
                  className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-[#19bc5a] focus:ring-[#19bc5a] text-base py-3"
                />
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">WhatsApp</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  minLength={11}
                  maxLength={11}
                  pattern="\d{11}"
                  placeholder="Digite seu WhatsApp com DDD"
                  className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-[#19bc5a] focus:ring-[#19bc5a] text-base py-3"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Digite seu melhor email"
                  className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-[#19bc5a] focus:ring-[#19bc5a] text-base py-3"
                />
              </div>

              <div>
                <label htmlFor="zip_code" className="block text-sm font-medium text-gray-700 mb-1">CEP</label>
                <input
                  type="text"
                  id="zip_code"
                  name="zip_code"
                  value={formData.zip_code}
                  onChange={handleChange}
                  required
                  minLength={8}
                  maxLength={8}
                  pattern="\d{8}"
                  placeholder="Digite seu CEP"
                  className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-[#19bc5a] focus:ring-[#19bc5a] text-base py-3"
                />
              </div>

              <button
                type="submit"
                className="w-full flex justify-center py-4 px-4 border border-transparent rounded-xl text-base font-semibold text-white bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#19bc5a] disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200 mt-6"
              >
                QUERO ANTECIPAR MEU FGTS
              </button>

              <p className="text-center text-sm text-gray-500 mt-4">
                Ao enviar, você concorda em receber contato via WhatsApp
              </p>
            </form>
          </div>
        </div>

        <div className="mt-6 space-y-4 px-4">
          <div className="flex items-center gap-3 text-[#19bc5a]">
            <div className="w-8 h-8 rounded-full bg-[#19bc5a]/10 flex items-center justify-center">✓</div>
            <p className="text-sm">Aprovação em poucos minutos</p>
          </div>
          <div className="flex items-center gap-3 text-[#19bc5a]">
            <div className="w-8 h-8 rounded-full bg-[#19bc5a]/10 flex items-center justify-center">✓</div>
            <p className="text-sm">Dinheiro na conta em até 24h</p>
          </div>
          <div className="flex items-center gap-3 text-[#19bc5a]">
            <div className="w-8 h-8 rounded-full bg-[#19bc5a]/10 flex items-center justify-center">✓</div>
            <p className="text-sm">Atendimento 100% digital</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadForm;
