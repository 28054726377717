import React, { useState, useEffect, useCallback } from 'react';
import simulationService from '../../services/simulationService';
import { formatCurrency, formatDate } from '../../utils/formatters';
import { ChevronLeft, ChevronRight, ChevronDown, ChevronUp, Search } from 'lucide-react';
import toast from 'react-hot-toast';

const SimulationHistory = ({ selectedBank, onSelectBank }) => {
  const [loading, setLoading] = useState(false);
  const [allSimulations, setAllSimulations] = useState([]); // Todos os dados
  const [filteredSimulations, setFilteredSimulations] = useState([]); // Dados filtrados
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchCpf, setSearchCpf] = useState('');

  // Memoize a função loadSimulations para evitar recriações desnecessárias
  const loadSimulations = useCallback(async () => {
    try {
      setLoading(true);
      const response = await simulationService.getAllSimulations(
        currentPage,
        itemsPerPage,
        selectedBank?.code
      );
      
      setAllSimulations(response.items);
      setFilteredSimulations(response.items);
      setTotalPages(response.total_pages);
    } catch (error) {
      toast.error('Erro ao carregar histórico de simulações');
      console.error('Erro ao carregar histórico:', error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, itemsPerPage, selectedBank]);

  // Efeito para carregar simulações quando necessário
  useEffect(() => {
    if (isExpanded) {
      loadSimulations();
    }
  }, [currentPage, selectedBank, isExpanded, loadSimulations]);

  // Filtra as simulações quando o CPF de busca muda
  useEffect(() => {
    if (searchCpf) {
      const filtered = allSimulations.filter(sim => 
        sim.cpf.toLowerCase().includes(searchCpf.toLowerCase())
      );
      setFilteredSimulations(filtered);
    } else {
      setFilteredSimulations(allSimulations);
    }
  }, [searchCpf, allSimulations]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6 mt-6">
      <div 
        className="flex justify-between items-center hover:bg-gray-50 rounded-lg p-3 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h2 className="text-xl font-semibold">Histórico de Simulações</h2>
        <button className="p-2 hover:bg-gray-100 rounded-full">
          {isExpanded ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
        </button>
      </div>

      {isExpanded && (
        <>
          <div className="flex items-center space-x-4 mt-4 mb-6">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Buscar por CPF..."
                value={searchCpf}
                onChange={(e) => setSearchCpf(e.target.value)}
                className="w-full p-2 pr-8 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              />
              <Search className="absolute right-2 top-2.5 w-4 h-4 text-gray-500" />
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center p-8">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green-500"></div>
            </div>
          ) : filteredSimulations.length === 0 ? (
            <p className="text-gray-500 text-center py-4">
              Nenhuma simulação encontrada
            </p>
          ) : (
            <>
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4 justify-items-stretch">
                {filteredSimulations.map((simulation, index) => (
                  <div
                    key={index}
                    className={`p-4 rounded-lg border h-full flex flex-col justify-between ${
                      simulation.success
                        ? 'border-green-200 bg-green-50'
                        : 'border-red-200 bg-red-50'
                    }`}
                  >
                    <div>
                      <p className="font-medium mb-2">
                        CPF: {simulation.cpf}
                      </p>
                      <p className="text-sm text-gray-600 mb-1">
                        Banco: {simulation.bank_name}
                      </p>
                      {simulation.success ? (
                        <p className="text-green-600 font-medium">
                          Valor: {formatCurrency(simulation.available_amount)}
                        </p>
                      ) : (
                        <p className="text-red-600 text-sm">
                          Erro: {simulation.error_message}
                        </p>
                      )}
                    </div>
                    <p className="text-xs text-gray-500 mt-2">
                      {formatDate(simulation.timestamp)}
                    </p>
                  </div>
                ))}
              </div>

              <div className="flex justify-center items-center space-x-4 mt-6">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePageChange(currentPage - 1);
                  }}
                  disabled={currentPage === 1}
                  className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
                >
                  <ChevronLeft className="w-5 h-5" />
                </button>
                
                <span className="text-sm text-gray-600">
                  Página {currentPage} de {totalPages}
                </span>
                
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePageChange(currentPage + 1);
                  }}
                  disabled={currentPage === totalPages}
                  className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
                >
                  <ChevronRight className="w-5 h-5" />
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SimulationHistory;
