import { useState, useCallback } from 'react';
import { Upload, AlertCircle } from 'lucide-react';

export function FileUpload({ 
  onUpload, 
  acceptedTypes, 
  multiple = false,
  maxFileSize = 10 * 1024 * 1024 // 10MB por padrão
}) {
  const [dragActive, setDragActive] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);

  const handleUpload = useCallback(async (files) => {
    try {
      setError(null);
      setUploading(true);

      // Validar tamanho dos arquivos
      const invalidFiles = Array.from(files).filter(file => file.size > maxFileSize);
      if (invalidFiles.length > 0) {
        throw new Error(`Arquivos muito grandes: ${invalidFiles.map(f => f.name).join(', ')}`);
      }

      // Callback de progresso
      const onProgress = (percent) => {
        setProgress(percent);
      };

      await onUpload(files, onProgress);
      
    } catch (err) {
      setError(err.message);
      console.error('Erro no upload:', err);
    } finally {
      setUploading(false);
      setProgress(0);
    }
  }, [onUpload, maxFileSize]);

  return (
    <div className="w-full">
      <div
        className={`border-2 border-dashed rounded-lg p-8 text-center transition-colors
          ${dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}
          ${uploading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
        onDragEnter={() => setDragActive(true)}
        onDragLeave={() => setDragActive(false)}
        onDragOver={(e) => e.preventDefault()}
        onDrop={async (e) => {
          e.preventDefault();
          setDragActive(false);
          if (!uploading) {
            await handleUpload(e.dataTransfer.files);
          }
        }}
      >
        <input
          type="file"
          className="hidden"
          accept={acceptedTypes}
          multiple={multiple}
          onChange={async (e) => {
            if (e.target.files?.length) {
              await handleUpload(e.target.files);
            }
          }}
          disabled={uploading}
          id="file-upload"
        />

        <Upload className="w-12 h-12 mx-auto text-gray-400 mb-4" />
        
        {uploading ? (
          <div className="space-y-2">
            <p className="text-sm text-gray-600">Enviando...</p>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div 
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>
        ) : (
          <div>
            <label
              htmlFor="file-upload"
              className="cursor-pointer"
            >
              <p className="text-[#19bc5a] text-lg">
                Clique para selecionar ou arraste seus arquivos
              </p>
            </label>
            <p className="text-sm text-gray-500 mt-2">
              {multiple ? 'Arquivos aceitos' : 'Arquivo aceito'}: {acceptedTypes}
            </p>
          </div>
        )}
      </div>

      {error && (
        <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-lg flex items-start">
          <AlertCircle className="w-5 h-5 text-red-500 mr-2 flex-shrink-0 mt-0.5" />
          <p className="text-red-600">{error}</p>
        </div>
      )}
    </div>
  );
}