import React from 'react';
import { LogOut } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import logo from '../assets/decoTech-v1-horizon-white.png';

export function Header() {
  const { logout } = useAuth();

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <img
            src={logo}
            alt="DecoTech Logo"
            className="h-10 w-auto"
          />
        </div>
        <button
          onClick={logout}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#19bc5a]"
        >
          <LogOut className="h-4 w-4 mr-2" />
          Sair
        </button>
      </div>
    </header>
  );
}
