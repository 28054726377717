import React, { createContext, useContext, useState } from 'react';

const ProposalContext = createContext();

export const ProposalProvider = ({ children }) => {
  const [financialId, setFinancialId] = useState(null);
  const [simulationData, setSimulationData] = useState(null);

  const clearProposalData = () => {
    setFinancialId(null);
    setSimulationData(null);
  };

  return (
    <ProposalContext.Provider 
      value={{ 
        financialId, 
        setFinancialId, 
        simulationData, 
        setSimulationData,
        clearProposalData 
      }}
    >
      {children}
    </ProposalContext.Provider>
  );
};

export const useProposal = () => {
  const context = useContext(ProposalContext);
  if (!context) {
    throw new Error('useProposal must be used within a ProposalProvider');
  }
  return context;
};
