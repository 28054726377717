import { useEffect, useState } from 'react';
import { chatService } from '../services/api';
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  LineChart, Line, Legend, AreaChart, Area
} from 'recharts';
import { TrendingUp, Users, MessageSquare, Clock } from 'lucide-react';

// Função auxiliar para formatar números
const formatNumber = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k';
  }
  return num;
};

// Função auxiliar para formatar porcentagem
const formatPercent = (num) => {
  return num.toFixed(1) + '%';
};

export function Monitor() {
  const [estatisticas, setEstatisticas] = useState({
    total_sessions: 0,
    active_today: 0,
    success_rate: 0,
    avg_duration_minutes: 0,
    total_messages: 0,
    completed_proposals: 0,
    hourly_stats: Array.from({ length: 24 }, (_, i) => ({ hora: i, conversas: 0 })),
    daily_stats: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'].map(dia => ({ dia, conversas: 0 })),
    conversion_timeline: Array.from({ length: 7 }, (_, i) => ({ 
      dia: new Date(Date.now() - (6-i) * 24 * 60 * 60 * 1000).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' }), 
      taxa: 0 
    }))
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    carregarEstatisticas();
    const interval = setInterval(carregarEstatisticas, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const carregarEstatisticas = async () => {
    try {
      setLoading(true);
      const [dados, metrics] = await Promise.all([
        chatService.getStats(),
        chatService.getMessageMetrics()
      ]);
      
      // Atualiza as estatísticas básicas
      setEstatisticas(prev => ({
        ...prev,
        ...dados,
        total_sessions: dados.total_sessions,
        active_today: dados.active_today,
        success_rate: dados.success_rate,
        avg_duration_minutes: dados.avg_duration_minutes,
        total_messages: metrics.metrics.total_messages,
        completed_proposals: dados.completed_proposals,
        hourly_stats: metrics.hourly.labels.map((label, index) => ({
          hora: parseInt(label),
          conversas: metrics.hourly.data[index]
        })),
        daily_stats: metrics.weekly.labels.map((label, index) => ({
          dia: label,
          conversas: metrics.weekly.data[index]
        }))
      }));

      setError(null);
    } catch (erro) {
      console.error('Erro ao carregar estatísticas:', erro);
      setError('Não foi possível carregar as estatísticas. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#19bc5a]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 text-red-600 p-4 rounded-lg">
        {error}
        <button 
          onClick={carregarEstatisticas}
          className="mt-2 px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200"
        >
          Tentar novamente
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center border-b pb-4">
        <h2 className="text-xl font-semibold text-[#515151]">Dashboard de Monitoramento</h2>
        <button 
          onClick={carregarEstatisticas}
          className="px-4 py-2 bg-[#19bc5a] text-white rounded hover:bg-[#19bc5a]/90 transition-colors flex items-center gap-2"
        >
          <Clock className="w-4 h-4" />
          Atualizar
        </button>
      </div>

      {/* Cards de Métricas */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <StatCard
          icon={<Users className="w-6 h-6 text-[#19bc5a]" />}
          titulo="Total de Sessões"
          valor={formatNumber(estatisticas.total_sessions)}
          descricao="Conversas iniciadas"
        />
        <StatCard
          icon={<TrendingUp className="w-6 h-6 text-[#19bc5a]" />}
          titulo="Taxa de Sucesso"
          valor={formatPercent(estatisticas.success_rate)}
          descricao={`${formatNumber(estatisticas.completed_proposals)} propostas finalizadas`}
        />
        <StatCard
          icon={<MessageSquare className="w-6 h-6 text-[#19bc5a]" />}
          titulo="Total de Mensagens"
          valor={formatNumber(estatisticas.total_messages)}
          descricao={`${formatNumber(estatisticas.active_today)} ativos hoje`}
        />
      </div>

      {/* Gráficos */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Gráfico de Conversas por Hora */}
        <div className="bg-white p-4 rounded-lg border">
          <h3 className="text-lg font-medium text-[#515151] mb-4">Conversas por Horário</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={estatisticas.hourly_stats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="hora"
                  tickFormatter={(hora) => `${hora}h`}
                />
                <YAxis />
                <Tooltip 
                  formatter={(value) => [value, "Conversas"]}
                  labelFormatter={(hora) => `${hora}:00 - ${hora}:59`}
                />
                <Bar dataKey="conversas" fill="#19bc5a" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Gráfico de Conversas por Dia */}
        <div className="bg-white p-4 rounded-lg border">
          <h3 className="text-lg font-medium text-[#515151] mb-4">Volume Semanal</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={estatisticas.daily_stats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dia" />
                <YAxis />
                <Tooltip />
                <Area 
                  type="monotone" 
                  dataKey="conversas" 
                  fill="#19bc5a" 
                  stroke="#19bc5a"
                  name="Conversas"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Gráfico de Taxa de Conversão */}
        <div className="bg-white p-4 rounded-lg border lg:col-span-2">
          <h3 className="text-lg font-medium text-[#515151] mb-4">Taxa de Conversão</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={estatisticas.conversion_timeline}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dia" />
                <YAxis />
                <Tooltip 
                  formatter={(value) => [value + '%', "Taxa de Conversão"]}
                />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="taxa" 
                  stroke="#19bc5a" 
                  name="Taxa de Conversão"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

function StatCard({ icon, titulo, valor, descricao }) {
  return (
    <div className="bg-white p-6 rounded-lg border hover:shadow-md transition-shadow">
      <div className="flex items-start justify-between">
        {icon}
        <span className="text-2xl font-bold text-[#515151]">{valor}</span>
      </div>
      <h3 className="text-[#515151] font-medium mt-2">{titulo}</h3>
      <p className="text-sm text-gray-500 mt-1">{descricao}</p>
    </div>
  );
}