import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import simulationService from '../../services/simulationService';
import { SimulationForm } from './SimulationForm';
import { BankResults } from './BankResults';
import SimulationHistory from './SimulationHistory';

export const SimulationPage = () => {
  const [banks, setBanks] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [simulationResults, setSimulationResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBanks, setLoadingBanks] = useState(true);

  useEffect(() => {
    loadBanks();
  }, []);

  const loadBanks = async () => {
    try {
      const data = await simulationService.getBanks();
      setBanks(data);
    } catch (err) {
      toast.error('Erro ao carregar bancos disponíveis', {
        duration: 5000,
        position: 'top-right',
        style: {
          background: '#fef2f2',
          color: '#991b1b',
          padding: '16px',
          borderRadius: '8px',
          border: '1px solid #fee2e2',
        }
      });
    } finally {
      setLoadingBanks(false);
    }
  };

  const handleSimulation = async (cpf) => {
    try {
      setLoading(true);
      localStorage.setItem('lastCpf', cpf); // Salva o CPF para histórico
      const loadingToast = toast.loading('Consultando simulações...', {
        position: 'top-right'
      });

      const results = await simulationService.simulate(cpf, selectedBank?.code);
      setSimulationResults(Array.isArray(results) ? results : [results]);
      
      toast.dismiss(loadingToast);
      toast.success('Simulação realizada com sucesso!', {
        duration: 4000,
        position: 'top-right',
        style: {
          background: '#f0fdf4',
          color: '#166534',
          padding: '16px',
          borderRadius: '8px',
          border: '1px solid #dcfce7',
        },
      });
    } catch (err) {
      toast.error(err.message, {
        duration: 5000,
        position: 'top-right',
        style: {
          background: '#fef2f2',
          color: '#991b1b',
          padding: '16px',
          borderRadius: '8px',
          border: '1px solid #fee2e2',
        }
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="border-b pb-4">
        <h2 className="text-2xl font-semibold text-gray-900">Simulação FGTS</h2>
        <p className="mt-1 text-sm text-gray-600">
          Consulte os valores disponíveis para saque do FGTS.
        </p>
      </div>
      
      <div className="grid grid-cols-1 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <SimulationForm 
            onSubmit={handleSimulation} 
            loading={loading} 
            banks={banks}
            selectedBank={selectedBank}
            onBankChange={setSelectedBank}
            loadingBanks={loadingBanks}
          />
        </div>

        {simulationResults && (
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Resultados da Simulação</h3>
            <BankResults results={simulationResults} />
          </div>
        )}

        <div className="bg-white rounded-lg shadow p-6">
          <SimulationHistory />
        </div>
      </div>
    </div>
  );
};
