export const formatCurrency = (value) => {
  if (!value && value !== 0) return '-';
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
};

export const formatDate = (dateString) => {
  if (!dateString) return '-';
  
  // Ajusta o timezone para Brasília (UTC-3)
  const date = new Date(dateString);
  const brasiliaOffset = 180; // 3 horas em minutos
  const utcMinutes = date.getUTCMinutes();
  const utcHours = date.getUTCHours();
  
  date.setUTCHours(utcHours);
  date.setUTCMinutes(utcMinutes - brasiliaOffset);

  const options = {
    timeZone: 'America/Sao_Paulo',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  };
  
  return date.toLocaleString('pt-BR', options);
};
