import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { simulateCredit } from '../../services/vctexService';
import simulationService from '../../services/simulationService';
import { ProposalForm } from './ProposalForm';
import { useProposal } from '../../context/ProposalContext';

export const ProposalPage = () => {
  const [banks, setBanks] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingBanks, setLoadingBanks] = useState(true);
  const { setSimulationData } = useProposal();

  useEffect(() => {
    loadBanks();
  }, []);

  const loadBanks = async () => {
    try {
      const data = await simulationService.getBanks();
      setBanks(data);
    } catch (err) {
      toast.error('Erro ao carregar bancos disponíveis');
    } finally {
      setLoadingBanks(false);
    }
  };

  const handleSimulation = async (cpf) => {
    try {
      setLoading(true);
      localStorage.setItem('lastCpf', cpf);
      
      const result = await simulateCredit(cpf, selectedBank?.code || 0);
      setSimulationData(result);
      
      toast.success(result.message);
      return result;
    } catch (error) {
      toast.error(error.message || 'Erro ao realizar simulação');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleBankChange = (bank) => {
    setSelectedBank(bank);
  };

  return (
    <div className="space-y-6">
      <div className="border-b pb-4">
        <h2 className="text-2xl font-semibold text-gray-900">Proposta</h2>
        <p className="mt-1 text-sm text-gray-600">
          Preencha as etapas do formulário para realizar a proposta.
        </p>
      </div>

      <div className="grid grid-cols-1 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <ProposalForm
            simulationSubmit={handleSimulation}
            loading={loading}
            banks={banks}
            selectedBank={selectedBank}
            onBankChange={handleBankChange}
            loadingBanks={loadingBanks}
          />
        </div>
      </div>
    </div>
  );
};
