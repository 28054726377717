import React, { useState } from 'react';

export const SimulationForm = ({
  onSubmit,
  loading,
  banks = [],
  selectedBank,
  onBankChange,
  loadingBanks
}) => {
  const [cpf, setCpf] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (cpf.length === 11) {
      onSubmit(cpf);
    }
  };

  const formatCPF = (value) => {
    const numbers = value.replace(/\D/g, '');
    setCpf(numbers);
  };

  const activeBanks = banks?.active_banks || [];

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="cpf" className="block text-sm font-medium text-gray-700">
            CPF
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="cpf"
              value={cpf}
              onChange={(e) => formatCPF(e.target.value)}
              maxLength={11}
              placeholder="Digite apenas números"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#19bc5a] focus:ring-[#19bc5a] sm:text-sm"
              required
              disabled={loading}
            />
          </div>
        </div>

        <div>
          <label htmlFor="bank" className="block text-sm font-medium text-gray-700">
            Banco
          </label>
          <div className="mt-1">
            <select
              id="bank"
              value={selectedBank?.code || ''}
              onChange={(e) => {
                const bank = activeBanks.find(b => b.code === e.target.value);
                onBankChange(bank || null);
              }}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-[#19bc5a] focus:ring-[#19bc5a] sm:text-sm"
              disabled={loading || loadingBanks}
            >
              <option value="">Todos os bancos</option>
              {activeBanks.map(bank => (
                <option key={bank.code} value={bank.code}>
                  {bank.name}
                </option>
              ))}
            </select>
          </div>
          {loadingBanks && (
            <p className="mt-1 text-sm text-gray-500">Carregando bancos disponíveis...</p>
          )}
        </div>

        <div>
          <button
            type="submit"
            disabled={cpf.length !== 11 || loading}
            className={`w-full inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm
              ${cpf.length === 11 && !loading
                ? 'bg-[#19bc5a] hover:bg-[#148f44] focus:outline-none focus:ring-2 focus:ring-[#19bc5a] focus:ring-offset-2'
                : 'bg-gray-400 cursor-not-allowed'
              }`}
          >
            {loading ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Consultando...
              </>
            ) : (
              'Consultar'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
