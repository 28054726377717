import React, { useState } from 'react';
import { SimulationForm } from '../components/simulation/SimulationForm';
import { BankResults } from '../components/simulation/BankResults';
import SimulationHistory from '../components/simulation/SimulationHistory';

const BASE_URL = process.env.REACT_APP_API_URL;

export const SimulationPage = () => {
  const [simulationResults, setSimulationResults] = useState(null);
  const [error, setError] = useState(null);

  const handleSimulation = async (cpf) => {
    try {
      setError(null);
      const response = await fetch(`${BASE_URL}/api/v1/simulation/${cpf}`);
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.detail || 'Erro ao realizar simulação');
      }

      setSimulationResults(data);
    } catch (err) {
      setError(err.message);
      setSimulationResults(null);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Simulação FGTS</h1>
      
      <div className="space-y-8">
        <SimulationForm onSubmit={handleSimulation} />
        
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
            {error}
          </div>
        )}
        
        {simulationResults && (
          <BankResults results={simulationResults} />
        )}
        
        <SimulationHistory />
      </div>
    </div>
  );
};
