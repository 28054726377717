import axios from 'axios';

// Remover o /api/v1 da baseURL já que está incluído na rota
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL 
});

export const createLead = async (leadData) => {
  try {
    const response = await api.post('/sessions', leadData);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar lead:', error.response?.data || error.message);
    throw error;
  }
};
